var words_in_list = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
    "post",
    "most",
    "spring", 
    "string", 
    "friend", 
    "add", 
    "egg", 
    "sit", 
    "clap", 
    "loud", 
    "cloud", 
    "town", 
    "round", 
    "sound", 
    "around", 
    "what", 
    "why", 
    "when", 
    "who", 
    "rain", 
    "water", 
    "forget", 
    "forgot"
]

function randomWord(){
    return words_in_list[Math.floor(Math.random()*words_in_list.length)]
}

export {randomWord}