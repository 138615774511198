import React from 'react';
import './App.css';
import Hangman from './components/Hangman';

function App() {
  return (
    <div className="App">
        <Hangman/>
    </div>
  );
}

export default App;
