import React, {Component} from 'react' ;
import './Hangman.css';
import {randomWord} from './Words.js';

import step0 from "./images/0.jpg";
import step1 from "./images/1.jpg";
import step2 from "./images/2.jpg";
import step3 from "./images/3.jpg";
import step4 from "./images/4.jpg";
import step5 from "./images/5.jpg";
import step6 from "./images/6.jpg";

class Hangman extends Component {

    static defaultProps = {
        maxWrong: 6,
        images: [step0, step1, step2 ,step3 ,step4 ,step5 ,step6]
    }

    generateButtons(){
        return "abcdefghijklmnopqrstuvwxyz".split("").map(letter => (
            <button className="btn btn-lg btn-primary m-2"
            key={letter}
            value={letter}
            onClick={this.handleGuess}
            disabled={this.state.guessed.has(letter)}
            >{letter}</button>
        ));
    }

    constructor(props) {
        super(props);
        this.state = {
            mistake: 0,
            guessed: new Set([]),
            answer: randomWord()
        }
    }

    handleGuess = e => {
        let letter = e.target.value;
        this.setState(st => ({
            guessed: st.guessed.add(letter).add(letter.toUpperCase()),
            //guessed: st.guessed.add(letter.toUpperCase()),
            mistake: st.mistake + (st.answer.includes(letter)|| st.answer.includes(letter.toUpperCase())? 0 : 1)
        }));
    }

    guessedWord() {
        return this.state.answer.split("").map(letter => (this.state.guessed.has(letter)|| this.state.guessed.has(letter.toLowerCase) ? letter : "_ "));
    }

    resetButton = () => {
        this.setState({
            mistake: 0,
            guessed: new Set([]),
            answer: randomWord()
        });
    }

    render() {
        const gameOver = this.state.mistake >= this.props.maxWrong;
        var holdGuessedWord = this.guessedWord().join("");
        var lowGuessedWord = holdGuessedWord.toLocaleLowerCase();
        const isWinner = (lowGuessedWord === this.state.answer.toLowerCase());
        let gameStat = this.generateButtons();

        if(isWinner) {
            gameStat = "You won!"
        }

        if(gameOver) {
            gameStat = "Try again."
        }
        return (
            <div className="Hangman container">
                <h1 className="text-center">Miss Verissimo's Class Hangman</h1>
                <div className="float-right">Incorrect Guesses: {this.state.mistake} of {this.props.maxWrong}</div>
                <div className="text-center">
                    <img src={this.props.images[this.state.mistake]} alt="game"/>
                </div>
                <div className="text-center">
                    <h3>
                        Guess the word:
                    </h3>
                    <p className="display-4">
                        {!gameOver ? this.guessedWord() : this.state.answer}
                    </p>
                    <div className="m-2">
                        <h2 >{gameStat}</h2>
                    </div>
                </div>
                <button className="btn btn-info" onClick={this.resetButton}>Reset</button>
            </div>
        )
    } 
}

export default Hangman;